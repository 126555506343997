body {
    margin: 0;
    font-family: sans-serif;
    width: 100vw;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}
