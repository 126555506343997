.text {
    width: 50rem;
    font-size: 12pt;
    font-weight: lighter;
}

.wrapper {
    margin-left: 10rem;
}

@media only screen and (max-width: 600px) {
    .text {
        width: 21rem;
    }

    .wrapper {
        margin-left: 2rem;
    }
}