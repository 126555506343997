.tabs {
    display: flex;
    justify-content: space-between;
    padding: 1rem;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
}

.links {
    text-decoration: none;
    color: black;
    font-size: 15pt;
}

@media only screen and (max-width: 600px) {
    .links {
        font-size: 10pt;
    }
    .tabs {
        justify-content: center;
        gap: 2rem;
    }
}
