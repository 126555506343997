.tabs--bottom {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.startimgs {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    height: 65vh;
    overflow: auto;
}

.imgelem {
    width: 31%;
    object-fit: cover;
    margin-left: 0.9rem;
    margin-bottom: 1rem;
    margin-right: 0.5rem;
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);
}

@media only screen and (max-width: 600px) {
    .startimgs {
        height: 65vh;
    }
    .imgelem {
        height: 12rem;
        max-width: 15rem;
        width: unset;
    }
}

.startimg {
    display: flex;
    justify-content: center;
}

.imgelem:hover {
    filter: grayscale(0%);
}
