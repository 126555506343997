.wakeup {
    display: flex;
    font-size: 60pt;
    font-weight: bold;
}

.info {
    font-size: 10pt;
    display: flex;
    width: 10rem;
}

.info_wrapper {
    margin-top: 1rem;
}

.where {
    display: flex;
    width: 60px;
}

.infoWhere {
    white-space: nowrap;
    width: 60%;
}

.infoWhat {
    white-space: nowrap;
    width: 40%;
}

.projectContainer {
    display: flex;
    flex-wrap: nowrap;
    margin-left: 1rem;
    gap: 2rem;
}

.projektContent {
    display: flex;
    align-items: center;
    width: 100%;
    overflow: auto;
    height: 65%;
}

.scroll-container {
    width: 100%;
    overflow-x: auto;
}

.scroll-content {
    display: flex;
}

.projektContentDonate {
    display: flex;
    align-items: baseline;
    margin-top: auto;
    margin-bottom: auto;
    width: 100%;
    overflow: auto;
    height: 67%;
}

.scroll {
    font-size: 12pt;
    font-weight: lighter;
}

.projectText {
    width: 30rem;
    /* margin-right: 2rem; */
    margin-left: 2rem;
    font-size: 12pt;
    font-weight: lighter;
    hyphens: auto;
    line-height: 1.5rem;
    text-align: justify;
}

.projectTextKontakt {
    margin-right: 2rem;
    margin-left: 2rem;
    font-size: 12pt;
    font-weight: lighter;
    hyphens: auto;
    line-height: 1.5rem;
    text-align: justify;
}

.txtimg {
    display: flex;
    margin-top: auto;
    margin-bottom: auto;
}

@media only screen and (max-width: 600px) {
    .info {
        display: flex;
        flex-direction: column;
        width: 15rem;
        font-size: 7pt;
    }
    .info_wrapper {
        margin-top: 0;
    }
    .wakeup {
        font-size: 15pt;
    }
    .projektContent {
        height: 56%;
    }
    .projektContentDonate {
        height: 56%;
    }
}
